export const zoom = 15;
export const center = [502550,5554000];
export const extent = [499000,5550900,506100,5557100];

export const logoPath = './logo_rodenbach.png';
export const logoSize = [45,15];
export const logoType = 'PNG';
export const attributions = ['<a href="https://www.rodenbach.de/Quicknavigation/Impressum/">Impressum</a> | <a href="https://www.rodenbach.de/Quicknavigation/Datenschutzhinweis/">Datenschutz</a> | '];
export const weg = [];

export const dop = {
    url:    '/geoserver/rodenbach/wms', 
    layer:  '',
    serverType: 'geoserver'
};
export const alkis = {
    url:    '/geoserver/rodenbach/wms', 
    layer:  '',
    serverType: 'geoserver'
};
export const fnp = {
    url:    '/geoserver/rodenbach/wms', 
    layer:  '',
    serverType: 'geoserver'
};