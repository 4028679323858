import { useState, useEffect } from 'react'

import { 
	Col, 
	Drawer, 
	Form,
	Input,
	Row  } from 'antd';

const GgwALKIS = ({ visibleDbo, alkis, onClose } ) => {

    const [dbo, setDbo] = useState({});

    useEffect(() => {
//      console.log(dbo)
        setDbo(alkis?.properties)
    }, [alkis])

    const dboChange = (e) => {
        console.log(e)
        let updatedValue = {};
        updatedValue = {[e.target.name]:e.target.value};
        setDbo(dbo => ({
             ...dbo,
             ...updatedValue
        }));
    }    

    return(
        <div>
            <Drawer
                title={alkis?.id.charAt(0).toUpperCase() + alkis?.id.split(".")[0].slice(1)}
                className="ggw-dbo"
                placement="right"
                onClose={onClose}
                visible={visibleDbo}
                mask={false}		
                bodyStyle={{
                    backgroundColor: "#afafaf"
                }}
                >
                <Form layout="vertical">
                    <Row gutter={[8,8]} >					
                        <Col span={24}>
                            <Form.Item style={{ marginBottom: 4 }} label="Gemeinde">
                                <Input name='gemename' value={dbo.gemename} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item style={{ marginBottom: 4 }} label="Gemarkung">
                                <Input name='gemaname' value={dbo.gemaname} />
                            </Form.Item>
                        </Col>	
                        <Col span={24}>
                            <Form.Item style={{ marginBottom: 4 }} label="Gemarkungsnummer">
                                <Input name='gemarkung' value={dbo.gemarkung} />
                            </Form.Item>
                        </Col>	                        
                        <Col span={24}>
                            <Form.Item style={{ marginBottom: 4 }} label="Flur">
                                <Input name='flur' value={dbo.flur} />
                            </Form.Item>
                        </Col>								
                        <Col span={24}>
                            <Form.Item style={{ marginBottom: 4 }} label="Zähler">
                                <Input name='zaehler' value={dbo.zaehler} />
                            </Form.Item>
                        </Col>					
                        <Col span={24}>
                            <Form.Item style={{ marginBottom: 4 }} label="Nenner">
                                <Input name='nenner' value={dbo.nenner} />
                            </Form.Item>
                        </Col>								
                        <Col span={24}>
                            <Form.Item style={{ marginBottom: 4 }} label="Amtliche Fläche (m²)">
                                <Input name='amtflaeche' value={dbo.amtflaeche} />
                            </Form.Item>
                        </Col>				
                        <Col span={24}>
                            <Form.Item style={{ marginBottom: 4 }} label="Tatsächliche Nutzung">
                                <Input name='tn' value={dbo.tn} />
                            </Form.Item>
                        </Col>				                        				                        					
                    </Row>
                </Form>
            </Drawer>                
        </div>
    )
}

export default GgwALKIS